.faq {
  p {
    margin: 0px 0px;
  }

  &-item {
    cursor: pointer;
  }
}

.font-14 {
  font-size: 14px;
}
