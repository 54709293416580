@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/froala-design-blocks/dist/css/froala_blocks';

$fdb-block-padding: 50px;

.fdb-block {
  padding: $fdb-block-padding 0px;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.btn-default {
  color: #fff
}