$padding-top: 15px;
$img-height: 100px;
$img-width: 150px;

.navbar {
  padding: $padding-top 0.5rem;

  img {
    max-width: $img-width;
    max-height: $img-height;
  }
}
