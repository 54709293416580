.artist {
  margin: 0;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}

.btn-circle {
  width: 40px;
  height: 40px;
  padding: 8px 4px;
  border-radius: 20px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}


.gallery-wrapper {
  overflow: hidden;
}

.grid-item {
  padding-bottom: 3rem;
}

.serie-title {
  font-size: 1.6rem;
}

.serie-subtitle {
  font-size: 1.2rem;
  line-height: 1.3rem;
}

.serie-modal-header .close {
  margin: -1rem -1rem -1rem;
}

.serie-modal-header .modal-title {
  margin: 0 auto;
}