.concert-card {
  p.artist {
    margin: 0;
  }

  .concert-details {
    p {
      margin: 0;
    }
  }

  margin-bottom: 20px;
}